import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from 'react-bootstrap/Container';
import ImgHeader from './assets/back_tour.jpg';
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';
import ImgTourMap from './assets/tour-map-2024.jpg';

const googleFormUrlPrefix = 'https://docs.google.com/forms/d/e/1FAIpQLSfCGdDmvOzn0DRLo55k_6bN8nj6S1icle3jpUj1lfdM8nauBg/viewform?usp=pp_url&entry.9960490=';

const tUrl = (str) => {
  return googleFormUrlPrefix + str.replace(/ /g, '+');
}

const googleMyMapsUrl = "https://www.google.com/maps/d/edit?mid=1GmHNAz_Q5kpEEMFTxNFGM8WwZN201OE&usp=sharing";
const wsjUrl = "https://www.wsj.com/articles/chatgpt-ai-math-po-shen-loh-1e9f80dc?st=0fg615evonrtpm1&reflink=desktopwebshare_permalink";

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}
.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
`;

const TourDivStyled = styled.div`
  font-size: 40px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
  text-align: center;

  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
      background-color: #5755c3;
      border-color: #5755c3;
      width: 200px;
      height: 50px;
      border-radius: 30px;
      font-weight: bold;
  }
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
`;


export const Tour = () => {
  return (
    <Styles>
      <CustomJumbotron bgimage={ImgHeader}>Tour</CustomJumbotron>
      <Container style = {{paddingTop: 30, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

        <h2>
          Talks (mostly on AI's impact on math, jobs, and education)
        </h2>

        <PStyled>
          Curious why people are talking about these events? Read <a href={wsjUrl}>The Wall Street Journal's review of the talk.</a>
        </PStyled>

        <PStyled>
          Want Prof. Loh to speak to your whole school and its parent
          community? There is no cost for public schools, thanks to private
          schools which customarily provide an honorarium for invited
          Assembly speakers. <a
            href="https://www.roxburylatin.org/2024/01/24/dr-po-shen-loh-combines-ai-education-and-art/">Typical impact of talk.</a> To book, email <a
              href="mailto:hello@poshenloh.com">hello@poshenloh.com</a>.
        </PStyled>

        <PStyled>
          Scroll down for registration links. <a href={googleMyMapsUrl}>
            Click on map for scrollable, zoomable map</a>, with clickable
          map markers that say which date Prof. Loh will be there.
        </PStyled>

        <a href={googleMyMapsUrl}>
          <img src={ImgTourMap} alt="2023 Summer Tour Stop Map" style={{maxWidth: "100%"}} />
        </a>

        <PStyled>
          In the summer of <Link to="/tour2021">2021</Link>, Prof. Loh
          conducted the largest outdoor solo math tour in human history,
          holding events in public parks. They were so successful that he
          continued touring in <Link to="/tour2022">2022</Link>, <Link
            to="/tour2023">2023</Link>, and <Link to="/tour2024">2024</Link>,
          giving hundreds of talks each year, in 100+ cities. His current
          topic guides parents and students on how to dramatically adjust
          educational approaches in the era of ChatGPT. These fun events
          also inspire students to see math from a different angle and to
          embrace challenge. 
        </PStyled>

        <Row className="align-items-center" style={{marginTop: "2rem", marginBottom: "2rem"}}>
          <Col xs={12} lg={6}>
            <Video>
              <iframe
                title="live-iframe"
                style={{position: "absolute", top: 0, 
                  left: 0, width: "100%", height: "100%"}}
                src="https://www.youtube.com/embed/m_sWES62A5s?autoplay=1&mute=1"
                frameBorder={0}
                allowFullScreen
                allow="autoplay"
              />
            </Video>
          </Col>

          <Col xs={12} lg={6}>
            <PStyled>
              This tour is supported by <a
                href="https://live.poshenloh.com">LIVE</a>, the <a
                  href="https://www.cnn.com/world/professor-po-shen-loh-actors-classrooms-spc">new
                  online math school</a> that Prof. Loh founded, which
              revolutionizes live-video math instruction: teaching students
              the art of invention.
            </PStyled>

            <TourDivStyled>
              <a href="https://live.poshenloh.com">
                <Button variant="primary">
                  Learn LIVE
                </Button>
              </a>
            </TourDivStyled>
          </Col>
        </Row>

        <h2>
          Event Schedule
        </h2>

        <PStyled>
          Individual registration links to all upcoming tour stops are
          listed below. Register directly via the corresponding link if a
          time and location fit you. If you will miss (or already missed)
          Prof. Loh's visit to your area, you can also sign up to be
          notified whenever he comes next time. If the link does not go to
          a registration page, that particular event is not open to the
          public.
        </PStyled>

        <TourDivStyled>
          <a href="https://forms.gle/SXavNjEzRouX9uGk8">
            <Button variant="primary">
              Notify me
            </Button>
          </a>
        </TourDivStyled>

        <OLStyled>
          <li><a href="https://clphs.edu.my">Penang, Malaysia (Chung Ling Private High School): Fri Jan 3</a></li>
          <li><a href="https://www.facebook.com/techdomepenang/photos/-thriving-in-the-age-of-ai-free-registration-now-open-the-future-of-work-and-edu/585912550854355">Penang, Malaysia (Tech Dome): Sat Jan 4 at 2:00pm</a></li>
          <li>Bogotá, Colombia (National Colombian Math Olympiad training camp): Tue Jan 7 and Wed Jan 8</li>
          <li><a href="https://maceio.com.br/educacao/workshop-internacional-de-altas-habilidades-em-ciencias-educadores-se-reunem-para-discutir-talentos-excepcionais">Maceió, Brazil (Workshop Internacional para Altas Habilidades em Ciências no Ensino Básico, Centro de Inovação do Jaraguá): Thu Jan 9 at 12:00pm</a></li>
          <li>Recife, Brazil (Colégio GGE): Fri Jan 10 at 1:30pm</li>
          <li><a href="https://cloud.conteudo.sistemaetapa.com.br/sigmacampbrazil">São Paulo, Brazil (Sigmacamp): Sat Jan 11</a></li>
          <li><a href="https://ralston.brssd.org">San Francisco (Belmont, Ralston Middle School): Tue Jan 14</a></li>
          <li><a href="https://nesbit.brssd.org">San Francisco (Belmont, Nesbit School): Tue Jan 14</a></li>
          <li><a href="https://www.caltechmathmeet.org">Los Angeles (Caltech Math Meet): Sat Jan 25</a></li>
          <li><a href="https://www.eventbrite.com/e/1143291508679">Orange County, CA (Lake Forest): Sat Jan 25 at 3:00pm</a></li>
          <li><a href="https://www.ctspe.net/mathcounts">Fairfield, CT (MATHCOUNTS Southwest CT Chapter Competition): Sat Feb 1</a></li>
          <li><a href="https://www.milton.edu/events/future-of-ai-with-po-shen-loh">Boston, MA (Milton Academy): Tue Feb 4 at 7:00pm</a></li>
          <li>Santa Fe, NM (Nina Otero Community School): Thu Feb 6</li>
          <li>Santa Fe, NM (Ortiz Middle School): Thu Feb 6</li>
          <li>Santa Fe, NM (El Camino Real Academy): Thu Feb 6</li>
          <li>Albuquerque, NM (Albuquerque Math Teachers Circle): Thu Feb 6</li>
          <li>Albuquerque, NM (Hayes Middle School): Fri Feb 7</li>
          <li>Albuquerque, NM (Jefferson Middle School): Fri Feb 7</li>
          <li>Albuquerque, NM (University of New Mexico math department colloquium): Fri Feb 7</li>
          <li><a href="https://stat.unm.edu/news-events/events/unm-pnm-math-contest-public-lecture-po-shen-loh-carnegie-mellon-university">Albuquerque, NM (UNM-PNM Statewide Math Contest): Sat Feb 8 at 10:00am</a></li>
          <li><a href="https://www.mphschool.org">Syracuse, NY (Manlius Pebble Hill School): Tue Feb 11</a></li>
          <li><a href="https://insightsedu.educationdynamics.com/event/4e9323d9-d9df-44a2-95ce-70bfe4155686">New Orleans, LA (InsightsEDU conference keynote): Thu Feb 13</a></li>
          <li>Orlando, FL (Florida Philanthropic Network Statewide Summit keynote): Thu Feb 20</li>
          <li>Shanghai, China (Shanghai American School): Fri Feb 28</li>
          <li>Shanghai, China: Sat Mar 1, afternoon</li>
          <li>Ningbo, China (Concordia School of Ningbo 宁波协和学校): Sun Mar 2 at 10:00am</li>
          <li>Suzhou, China (Nord Anglia School Suzhou 苏州市相城区诺德学校): Mon Mar 3</li>
          <li>Suzhou, China (Nord Anglia Chinese International School Shanghai 上海闵行区诺达双语学校): Mon Mar 3</li>
          <li>Shanghai, China (Nord Anglia School Jiaxin 嘉兴市诺德安达学校): Tue Mar 4</li>
          <li>Chengdu, China (BASIS International & Bilingual Schools Chengdu): Wed Mar 5</li>
          <li>Chengdu, China (Malvern College Chengdu 成都墨尔文学校): Wed Mar 5</li>
          <li>Hong Kong (Dalton School Hong Kong): Thu Mar 6</li>
          <li>Hong Kong (St. Margaret's Co-educational English Secondary School): Fri Mar 7</li>
          <li>Hong Kong (Baptist Liu Ming Choi School): Sat Mar 8</li>
          <li>Shanghai, China: Sun Mar 9</li>
          <li><a href="https://www.harkermathinvitational.org">San Jose, CA (Harker Math Invitational): Sat Mar 15</a></li>
          <li><a href="https://docs.google.com/forms/d/e/1FAIpQLSfwuel7MQj4wQObM9neiM14mEQuxWIavO3lmRm0izEvPahULw/viewform">Princeton, NJ (Summer Camp & Community Fair): Sun Mar 16</a></li>
          <li><a href="https://compete.gunnmathcircle.org">Palo Alto, CA (Gunn Math Competition): Sun Mar 30</a></li>
          <li><a href="https://www.brumo.org/">Providence, RI (Brown University Math Olympiad): Sat Apr 5</a></li>
          <li><a href={tUrl("RI Providence")}>Providence, RI: Sat Apr 5 in afternoon</a></li>
          <li><a href={tUrl("MA Boston")}>Boston, MA: Sun Apr 6</a></li>
          <li><a href="https://berkeley.mt">Berkeley, CA (Berkeley mini Math Tournament): Sat Apr 12</a></li>
          <li><a href="https://nyc.nj.integirls.org">New Brunswick, NJ (NJ INTEGIRLS at Rutgers): Sat May 3</a></li>
          <li><a href="https://www.mathcon.org">Chicago, IL (National MathCON Finals): Sat May 10</a></li>
          <li><a href={tUrl("IL Chicago")}>Chicago, IL: Sat May 10 in afternoon</a></li>
          <li><a href="https://www.mathcounts.org">Washington, DC (MATHCOUNTS National Competition, no talk): May 11&ndash;12</a></li>
          <li><a href="https://stemtalentfund.org">London, United Kingdom (Purposeful Ventures STEM Tech Talent Fund Summit): May 15&ndash;16</a></li>
          <li><a href="https://canadam.ca/2025">Ottawa, Canada (Canadian Discrete and Algorithmic Mathematics Conference Public Interest Lecture): Tue May 20 at 6:30pm</a></li>
          <li><a href={tUrl("HK Hong Kong")}>Hong Kong: Thu May 22</a></li>
          <li><a href="https://www.seedasdan.asia/en/bmmt-en">Beijing, China (ASDAN mini Math Tournament at Berkeley): May 24&ndash;25</a></li>
          <li><a href="https://www.sipa.columbia.edu/sipa-education/executive-education/courses/ypo-value-creation-and-investing">New York, NY (Young Presidents' Organization Global One Columbia Business School Academy): Thu Jun 19</a></li>
          <li><a href="https://www.davidsongifted.org/gifted-programs/summer-opportunities/annual-family-events/summit">Reno, NV (Davidson Summit): June 20&ndash;22</a></li>
          <li><a href="https://sumac.spcs.stanford.edu">Stanford University Math Camp: Tue Jun 24</a></li>
          <li><a href="https://www.txst.edu/mathworks/camps/summer-math-camps-information/hsmc.html">San Marcos, TX (Mathworks Honors Summer Math Camp): Wed Jun 25</a></li>
          <li><a href="https://www.mathmountains.org">Jackson, WY (Math in the Mountains): Jun 26&ndash;29</a></li>
          <li><a href="https://rossprogram.org">Columbus, OH (Ross Mathematics Program): Mon Jun 30</a></li>
          <li><a href={tUrl("OH Columbus")}>Columbus, OH: Mon Jun 30 at 7:00pm</a></li>
          <li><a href="https://pgretreat.org">Mount Sterling, OH (PG Retreat): Tue Jul 1</a></li>
          <li><a href="https://hcssim.org">Amherst, MA (Hampshire College Summer Studies in Mathematics: Thu Jul 3</a></li>
          <li><a href="https://www.mathcamp.org">Portland, OR (Canada/USA Mathcamp): Jul 4&ndash;5</a></li>
          <li><a href="http://www.mualphatheta.org/national-convention">Orlando, FL (Mu Alpha Theta National Convention): Jul 6&ndash;7</a></li>
          <li><a href="https://www.imscprogram.com">Beijing, China (International Mathematics Summer Camp, Beijing Institute of Mathematical Sciences and Applications): Wed Jul 9</a></li>
          <li><a href={tUrl("NSW Sydney")}>Sydney, Australia: Sat Jul 12</a></li>
          <li><a href="https://imo2025.au">Sunshine Coast, Australia (International Mathematical Olympiad and IMO Alumni Reunion): Jul 13&ndash;21</a></li>
          <li><a href="https://epsiloncamp.org">St. Louis, MO (Epsilon Camp): Fri Jul 25</a></li>
          <li><a href="https://www.montana.edu/smrc/summer-science.html">Bozeman, MT (Montana STEM Summer Institute): Wed Jul 30</a></li>
        </OLStyled>

        <h2>Stay Notified</h2>

        <PStyled>
          To stay notified about future tour stops, please subscribe to our <a
            href="https://eepurl.com/gExYzT">email newsletter</a> which sends
          timely information about math education and contest deadlines, every
          two weeks. If your language preference is Chinese, please follow the
          WeChat Public Account: 罗博深数学.
        </PStyled>

        <PStyled>
          To follow future online live streams, please subscribe to our <a
            href="https://www.youtube.com/channel/UCf78EJOm4wQ4xXwSS15PuxQ">Daily
            Challenge with Po-Shen Loh YouTube Channel</a>.
        </PStyled>

        <h2>Learn Math</h2>

        <PStyled>
          To learn math with Prof. Loh on a more regular basis, visit our <a href="https://live.poshenloh.com">classes</a>.
        </PStyled>

      </Container>
      <Footer />
    </Styles>
  );
}
